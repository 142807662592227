import { SystemStyleObject } from '@chakra-ui/theme-tools';

const layerStyles: SystemStyleObject = {
  base: {
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.20',
  },
  hover: {
    bg: 'purple.10',
    border: '1px solid',
    borderColor: 'purple.30',
  },
  disabled: {
    bg: 'gray.10',
    border: '1px solid',
    borderColor: 'gray.20',
    color: 'gray.40',
  },
};

export default layerStyles;
