import { SurferIdSchema } from 'shared-types';
import z from 'zod';

export const OrganizationReportingSettingsSchema = z.object({
  allowSetReportTime: z.boolean(),
  trackUnscheduledOvertime: z.boolean(),
  timeReportingSurferIds: z.array(SurferIdSchema),
});

export type OrganizationReportingSettings = z.infer<typeof OrganizationReportingSettingsSchema>;

const PersistedOrganizationReportingSettingsSchema = OrganizationReportingSettingsSchema.merge(
  z.object({ id: z.number() }),
);

export const GetOrgReportingSettingsRespSchema = PersistedOrganizationReportingSettingsSchema;

export const UpdateOrgReportingSettingsReqSchema = OrganizationReportingSettingsSchema.partial();

export type UpdateOrgReportingSettingsReq = z.infer<typeof UpdateOrgReportingSettingsReqSchema>;

export const UpdateOrgReportingSettingsRespSchema = PersistedOrganizationReportingSettingsSchema;
