import { ButtonProps, Button as ChakraButton, forwardRef } from '@chakra-ui/react';
import React, { forwardRef as reactForwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useShouldHardNavigate } from '../../../hooks/useNavigationConfig';
import { Icon, IconName } from '../Icon';
import { Progress } from '../Progress';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'large';

export interface ButtonBaseProps extends ButtonProps {
  isOn?: boolean;
  label?: string;
  labelIfOn?: string;
  iconIfOn?: IconName;
  size?: ButtonSize;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-active'
    | 'danger'
    | 'success'
    | 'link';
  stretched?: boolean;
  align?: 'left' | 'center' | 'right' | 'between';
  iconLeft?: IconName;
  iconRight?: IconName;
  iconSize?: string;
  inProgress?: boolean;
  to?: string | LinkProps['to'];
  href?: string;
  externalLink?: boolean;
  newStyle?: boolean;
  target?: string;
}

type ConfigAwareLinkProps = {
  children: React.ReactNode;
  style: React.CSSProperties;
  target: '_blank' | '_self';
  to: Exclude<ButtonBaseProps['to'], undefined>;
};

const ConfigAwareLink = reactForwardRef<HTMLAnchorElement, ConfigAwareLinkProps>(
  ({ children, to, target, style }, ref) => {
    const shouldHardNavigate = useShouldHardNavigate();

    const sharedProps = {
      ref,
      style,
      target,
    };

    if (shouldHardNavigate && typeof to === 'string') {
      return (
        <a href={to} {...sharedProps}>
          {children}
        </a>
      );
    }

    return (
      <Link to={to} {...sharedProps}>
        {children}
      </Link>
    );
  },
);

export const Button = forwardRef<ButtonBaseProps, 'button'>(
  (
    {
      as,
      isOn,
      label,
      labelIfOn,
      iconLeft,
      align,
      iconRight,
      iconSpacing,
      iconIfOn,
      iconSize,
      disabled = false,
      inProgress = false,
      type = 'button',
      size,
      variant: _variant = 'naked',
      className,
      children,
      stretched,
      to,
      href,
      externalLink = false,
      newStyle,
      ...rest
    },
    ref,
  ) => {
    const variant = newStyle ? `new-${_variant}` : _variant;

    // TODO: can we infer label from the text content of `children`?
    const getActiveLabel = () => (isOn ? labelIfOn || label : label);
    const activeLabel = getActiveLabel();

    const getActiveIcon = (fallback: IconName): IconName =>
      isOn && iconIfOn ? iconIfOn : fallback;

    const alignDirections = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
      between: 'space-between',
    };

    const sharedButtonProps = {
      className,
      type,
      title: activeLabel,
      'aria-label': activeLabel,
      leftIcon: iconLeft && <Icon name={getActiveIcon(iconLeft)} size={iconSize} />,
      rightIcon: iconRight && <Icon name={getActiveIcon(iconRight)} size={iconSize} />,
      iconSpacing: iconSpacing,
      isLoading: inProgress,
      variant: variant,
      size: size,
      justifyContent: align ? alignDirections[align] : 'center',
      width: stretched ? '100%' : 'fit-content',
    };

    if (as === 'a') {
      return (
        <ConfigAwareLink
          to={to || href || ''}
          target={externalLink ? '_blank' : '_self'}
          ref={ref}
          style={{ width: stretched ? '100%' : undefined }}
        >
          <ChakraButton as="span" {...sharedButtonProps} {...rest}>
            {children}
          </ChakraButton>
        </ConfigAwareLink>
      );
    }

    return (
      <ChakraButton
        ref={ref}
        as={as}
        isDisabled={disabled || inProgress}
        spinner={<Progress running />}
        {...sharedButtonProps}
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  },
);
