const Input = {
  baseStyle: {
    field: {
      border: '1px solid',
      borderColor: 'gray.20',
      borderRadius: '4px',
      color: 'gray.50',
      transition: '0.3s ease-in border',
      _hover: {
        borderColor: 'purple.30',
      },
      _active: {
        borderColor: 'purple.30',
      },
    },
  },
  sizes: {},
  defaultProps: {},
  variants: {
    outline: {
      field: {
        height: 'auto',
        border: '1px solid',
        borderColor: 'gray.20',
        color: 'gray.50',
        _hover: {
          borderColor: 'purple.30',
        },
        _focus: {
          borderColor: 'purple.30',
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: 'red.error',
          boxShadow: 'none',
        },
      },
    },
  },
};

export default Input;
