import { BRAND } from 'zod';

import { rawTimezoneCountryCodeMap } from './zoneList';

// separate definition here to avoid circular dependency
type TimezoneIana = string & BRAND<'TimezoneIana'>;

type CountryCode = string & BRAND<'CountryCode'>;

export const timezoneCountryCodeMap = rawTimezoneCountryCodeMap as Record<
  TimezoneIana,
  CountryCode
>;
export const ianaTimezones = Object.keys(timezoneCountryCodeMap).sort() as TimezoneIana[];

// separate because we don't want to show it to users
export const UTC_TIMEZONE = 'Etc/UTC' as TimezoneIana;

const timezoneSet = new Set<string>([...ianaTimezones, UTC_TIMEZONE]);

export const isTimezoneIana = (value: string) => {
  return timezoneSet.has(value);
};
