import { z } from 'zod';

import { ManagerIdSchema, OrganizationIdSchema, SurferIdSchema } from './ids';

export const UserLoginSchema = z.object({
  email: z.string().optional(),
  lastLogin: z.string().optional(),
  invitationUrl: z.string().optional(),
});
export type UserLogin = z.infer<typeof UserLoginSchema>;

export const RoleSchema = z.enum(['manager', 'surfer', 'internal']);
export type Role = z.infer<typeof RoleSchema>;

export const AuthedUserSchema = z.object({
  organizationId: OrganizationIdSchema,
  surferId: SurferIdSchema.optional(),
  managerId: ManagerIdSchema.optional(),
  roles: RoleSchema.array().min(1),
});
export type AuthedUser = z.infer<typeof AuthedUserSchema>;
