import { extendTheme } from '@chakra-ui/react';

import Alert from './components/alert';
import Button from './components/button';
import Checkbox from './components/checkbox';
import FormErrorMessage from './components/formErrorMessage';
import FormLabel from './components/formLabel';
import Input from './components/input';
import Link from './components/link';
import Modal from './components/modal';
import Radio from './components/radio';
import Slider from './components/slider';
import { switchTheme } from './components/switch';
import Table from './components/table';
import Tooltip from './components/tooltip';
import borderRadius from './foundations/borderRadius';
import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import { fontSizes, fontWeights, fonts, lineHeights } from './foundations/fonts';
import layerStyles from './foundations/layerStyles';
import shadows from './foundations/shadows';
import spacing from './foundations/spacing';

export const theme = extendTheme({
  components: {
    Table,
    Alert,
    Button,
    Input,
    Checkbox,
    FormLabel,
    FormErrorMessage,
    Tooltip,
    Link,
    Slider,
    Radio,
    Modal,
    Switch: switchTheme,
  },
  colors,
  fonts,
  fontSizes,
  fontWeights,
  breakpoints,
  space: spacing,
  lineHeights,
  layerStyles,
  radii: borderRadius,
  shadows: shadows,
  styles: {
    global: {
      '.react-datepicker': {
        border: 'none',
        overflowX: 'hidden',
      },
      '.react-datepicker__header': {
        display: 'none',
      },
      '.react-datepicker__triangle': {
        display: 'none',
      },
      '.Timepicker__TimeItemsContainer': {
        overflowY: 'hidden !important',
      },
    },
  },
});

export default theme;
