import React from 'react';

import { Progress } from '../design-system/components/Progress';

export const CenteredLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      aria-label="Loading"
    >
      <Progress running={true} />
    </div>
  );
};
