import { z } from 'zod';

import { SurferIdSchema } from '.';
import { IntervalSchema } from './time';

/**
 * All the options used to configure a timesheet export.
 */
export const TimesheetOptionsSchema = z.object({
  /**
   * All the surfers to be included in the export.
   */
  surferIds: z.array(SurferIdSchema).min(1, { message: 'You need to select at least one surfer' }),
  /**
   * What type of
   */
  view: z.union([z.literal('shift'), z.literal('cumulative')]),
  interval: IntervalSchema,
  /**
   * Break the Cumulative version of the report into different shifts by name and time offs by subtype
   */
  breakdownShift: z.boolean().optional(),
  /**
   * Include the time offs in the report
   */
  includeTimeOff: z.boolean().optional(),
  /**
   * Include a breakdown of all the breaks in the report.
   */
  includeBreaks: z.boolean(),
  /**
   * Include a breakdown of all the activities in the report.
   */
  includeActivities: z.boolean(),
  /**
   * Include any deleted surfers.
   */
  includeDeletedSurfers: z.boolean().optional(),
});
export type TimesheetOptions = z.infer<typeof TimesheetOptionsSchema>;
