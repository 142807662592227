import { useAuth0 } from '@auth0/auth0-react';
import { ManagerId } from 'shared-types';

export const useManagerId = (): ManagerId | undefined => {
  const { user } = useAuth0();

  if (!user) {
    return undefined;
  }

  return user[import.meta.env.VITE_AUTH0_NAMESPACE + 'internal_manager_id'];
};
