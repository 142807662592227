import { z } from 'zod';

import { OrganizationIdSchema } from './ids';
import { dateString } from './time';
import {
  nonNegativeStringOrNumber,
  nonnegativeInteger,
  numberOrString,
  positiveInteger,
  positiveIntegerStringOrNumber,
} from './utils';

// paddle subscription webhook docs: https://developer.paddle.com/webhook-reference/ZG9jOjI1MzUzOTkz-subscription-alerts

const PaddleSubscriptionStatusSchema = z.union([
  z.literal('active'),
  z.literal('trialing'),
  z.literal('past_due'),
  z.literal('paused'),
  z.literal('deleted'),
]);
export type PaddleSubscriptionStatus = z.infer<typeof PaddleSubscriptionStatusSchema>;

const PaddlePaymentStatusSchema = z.union([
  z.literal('succeeded'),
  z.literal('failed'),
  z.literal('refunded'),
]);
export type PaddlePaymentStatus = z.infer<typeof PaddlePaymentStatusSchema>;

const PaddlePaymentMethod = z.union([z.literal('card'), z.literal('paypal')]);

export const PaddlePassThroughSchema = z.preprocess(
  a => {
    if (typeof a === 'string') {
      return JSON.parse(a || 'null');
    }
    return a;
  },
  z.object({ organizationId: OrganizationIdSchema }),
);

const PaddleWebhookPayloadSchemaBase = z.object({
  alert_id: z.string(),
  alert_name: z.string(),
  checkout_id: z.string(),
  currency: z.string(),
  email: z.string().email(),
  event_time: dateString,
  marketing_consent: z.union([z.literal('0'), z.literal('1')]),
  p_signature: z.string(),
  passthrough: PaddlePassThroughSchema,
  status: PaddleSubscriptionStatusSchema,
  subscription_id: z.string(),
  subscription_plan_id: z.string(),
  user_id: z.string(),
});

// we receive two "categories" of webhook events from paddle:
// 1) subscription-related events
// 2) payment-related events

// 1) subscription-related events:

const PaddleSubscriptionSchemaBase = PaddleWebhookPayloadSchemaBase;

export const PaddleSubscriptionCreatedSchema = PaddleSubscriptionSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_created'),
    cancel_url: z.string().url(),
    next_bill_date: dateString,
    quantity: positiveIntegerStringOrNumber,
    source: z.string(),
    unit_price: nonNegativeStringOrNumber,
    update_url: z.string().url(),
  }),
);
export type PaddleSubscriptionCreated = z.infer<typeof PaddleSubscriptionCreatedSchema>;

const PaddleSubscriptionUpdatedSchema = PaddleSubscriptionSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_updated'),
    cancel_url: z.string().url(),
    new_price: nonNegativeStringOrNumber,
    new_quantity: positiveIntegerStringOrNumber,
    new_unit_price: nonNegativeStringOrNumber,
    next_bill_date: dateString,
    old_next_bill_date: dateString,
    old_price: nonNegativeStringOrNumber,
    old_quantity: positiveIntegerStringOrNumber,
    old_status: PaddleSubscriptionStatusSchema,
    old_subscription_plan_id: z.string(),
    old_unit_price: nonNegativeStringOrNumber,
    update_url: z.string().url(),
    paused_at: z.string().optional(),
    paused_from: z.string().optional(),
    paused_reason: z.string().optional(),
  }),
);
export type PaddleSubscriptionUpdated = z.infer<typeof PaddleSubscriptionUpdatedSchema>;

const PaddleSubscriptionCancelledSchema = PaddleSubscriptionSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_cancelled'),
    cancellation_effective_date: dateString,
    quantity: positiveIntegerStringOrNumber,
    unit_price: nonNegativeStringOrNumber,
  }),
);
export type PaddleSubscriptionCancelled = z.infer<typeof PaddleSubscriptionCancelledSchema>;

export const PaddleSubscriptionEventSchemas = z.union([
  PaddleSubscriptionCreatedSchema,
  PaddleSubscriptionUpdatedSchema,
  PaddleSubscriptionCancelledSchema,
]);
export type PaddleSubscriptionEvent = z.infer<typeof PaddleSubscriptionEventSchemas>;

// 2) payment-related events:

const PaddlePaymentSchemaBase = PaddleWebhookPayloadSchemaBase.merge(
  z.object({
    instalments: positiveIntegerStringOrNumber,
    order_id: z.string(),
    quantity: positiveIntegerStringOrNumber,
    subscription_payment_id: z.string(),
    unit_price: nonNegativeStringOrNumber,
  }),
);

export const PaddlePaymentSucceededSchema = PaddlePaymentSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_payment_succeeded'),
    balance_currency: z.string(),
    balance_earnings: nonNegativeStringOrNumber,
    balance_fee: nonNegativeStringOrNumber,
    balance_gross: nonNegativeStringOrNumber,
    balance_tax: nonNegativeStringOrNumber,
    country: z.string(),
    coupon: z.string().optional(),
    customer_name: z.string(),
    earnings: nonNegativeStringOrNumber,
    fee: nonNegativeStringOrNumber,
    initial_payment: z.union([
      z.literal('0'),
      z.literal('1'),
      z.literal('false'),
      z.literal('true'),
    ]),
    next_bill_date: dateString,
    next_payment_amount: nonNegativeStringOrNumber,
    payment_method: PaddlePaymentMethod,
    payment_tax: nonNegativeStringOrNumber,
    plan_name: z.string(),
    receipt_url: z.string().url(),
    sale_gross: nonNegativeStringOrNumber,
  }),
);
export type PaddlePaymentSucceeded = z.infer<typeof PaddlePaymentSucceededSchema>;

export const PaddlePaymentFailedSchema = PaddlePaymentSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_payment_failed'),
    amount: nonNegativeStringOrNumber,
    attempt_number: positiveIntegerStringOrNumber,
    cancel_url: z.string().url(),
    next_retry_date: z.union([z.literal(''), dateString]),
    update_url: z.string().url(),
  }),
);
export type PaddlePaymentFailed = z.infer<typeof PaddlePaymentFailedSchema>;

export const PaddlePaymentRefundedSchema = PaddlePaymentSchemaBase.merge(
  z.object({
    alert_name: z.literal('subscription_payment_refunded'),
    amount: nonNegativeStringOrNumber,
    balance_currency: z.string(),
    balance_earnings_decrease: numberOrString,
    balance_fee_refund: nonNegativeStringOrNumber,
    balance_gross_refund: nonNegativeStringOrNumber,
    balance_tax_refund: nonNegativeStringOrNumber,
    earnings_decrease: numberOrString,
    fee_refund: nonNegativeStringOrNumber,
    gross_refund: nonNegativeStringOrNumber,
    initial_payment: z.union([z.literal('0'), z.literal('1')]),
    refund_reason: z.string(),
    refund_type: z.union([z.literal('full'), z.literal('vat'), z.literal('partial')]),
    tax_refund: nonNegativeStringOrNumber,
  }),
);
export type PaddlePaymentRefunded = z.infer<typeof PaddlePaymentRefundedSchema>;

export const PaddlePaymentEventSchema = z.union([
  PaddlePaymentSucceededSchema,
  PaddlePaymentFailedSchema,
  PaddlePaymentRefundedSchema,
]);
export type PaddlePaymentEvent = z.infer<typeof PaddlePaymentEventSchema>;

export const PaddleWebhookEventSchema = z.discriminatedUnion('alert_name', [
  PaddleSubscriptionCreatedSchema,
  PaddleSubscriptionUpdatedSchema,
  PaddleSubscriptionCancelledSchema,
  PaddlePaymentSucceededSchema,
  PaddlePaymentFailedSchema,
  PaddlePaymentRefundedSchema,
]);
export type PaddleWebhookEvent = z.infer<typeof PaddleWebhookEventSchema>;

// We transform paddle data into simpler models for our FE:

export const SusbcriptionSchema = z.object({
  planName: z.string(),
  currency: z.string(),
  unitPrice: nonNegativeStringOrNumber,
  quantity: positiveIntegerStringOrNumber,
  updateUrl: z.string().url().optional(), // won't exist for cancelled subscription
  nextBillDate: dateString.optional(),
  status: PaddleSubscriptionStatusSchema,
});
export type Subscription = z.infer<typeof SusbcriptionSchema>;

export const PaymentSchema = z.object({
  eventTime: dateString,
  orderId: z.string(),
  quantity: positiveIntegerStringOrNumber,
  paymentMethod: PaddlePaymentMethod.optional(),
  currency: z.string(),
  amount: nonNegativeStringOrNumber,
  tax: nonNegativeStringOrNumber.optional(),
  status: PaddlePaymentStatusSchema,
  receiptUrl: z.string().url().optional(), // will only exist for payment succeeded
});
export type Payment = z.infer<typeof PaymentSchema>;

export const BillingDataSchema = z.object({
  subscriptionPlanId: z.string(),
  totalUsers: nonnegativeInteger,
  subscription: SusbcriptionSchema.optional(),
  payments: z.array(PaymentSchema).optional(),
});
export type BillingData = z.infer<typeof BillingDataSchema>;

// used for the billing-seats cron job:

export const OrganizationSubscriptionSchema = z.object({
  organizationId: OrganizationIdSchema,
  subscriptionId: z.string(),
  subscriptionStatus: PaddleSubscriptionStatusSchema,
  currentSubscriptionSeats: positiveInteger,
});
export type OrganizationSubscription = z.infer<typeof OrganizationSubscriptionSchema>;

export const MonthlySeatCountPayloadSchema = z.array(
  z.object({
    month: z.string(),
    year: z.string(),
    change: z.object({
      surfersAdded: nonnegativeInteger,
      managersAdded: nonnegativeInteger,
      surfersRemoved: nonnegativeInteger,
      totalSurferSeats: nonnegativeInteger,
      totalManagerSeats: nonnegativeInteger,
      totalSeats: nonnegativeInteger,
    }),
  }),
);
export type MonthlySeatCountPayload = z.infer<typeof MonthlySeatCountPayloadSchema>;
