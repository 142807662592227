import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { QueryClientConfig, QueryOptions } from '@tanstack/react-query';
import { toCamel } from 'convert-keys';
import { PagedResults, TeamId, TeamIdSchema, pagedResultsSchema } from 'shared-types';
import { monotonicFactory } from 'ulidx';
import { z } from 'zod';

import { useIsSurfboardEmployee } from '../hooks/useIsSurfboardEmployee';
import { useManagerId } from '../hooks/useManagerId';
import { useSetShouldHardNavigate } from '../hooks/useNavigationConfig';
import { useOrgId } from '../hooks/useOrgId';
import { useRoles } from '../hooks/useRoles';
import { useSurferId } from '../hooks/useSurferId';
import { SESSION_ID } from '../session';

const generateRequestId = monotonicFactory();

const reloadIfBundleIsStale = (response: Response, setShouldHardNavigate: (v: boolean) => void) => {
  const serverCommitHash = response.headers.get('commit-hash');
  const serverHasDifferentCommitHash =
    import.meta.env.MODE !== 'staging' &&
    serverCommitHash != null &&
    serverCommitHash !== __COMMIT_HASH__;

  if (serverHasDifferentCommitHash) {
    if ([400, 500].includes(response.status)) {
      window.location.reload();
    } else {
      setShouldHardNavigate(true);
    }
  }
};

type FetchOptions =
  | {
      method: 'GET';
    }
  | {
      method: 'POST' | 'PATCH' | 'PUT' | 'DELETE';
      body?: string;
    };

type RequestOptions = FetchOptions & {
  version?: 'v0' | 'v1' | 'v2';
  useExactPathProvided?: boolean;
  searchParams?: Record<string, string>;
  transformKeys?: boolean;
};

type Unpaginated<MaybePaginated> =
  MaybePaginated extends PagedResults<infer T> ? T[] : MaybePaginated;

const isPaginated = <T>(input: unknown): input is PagedResults<T> => {
  return pagedResultsSchema(z.unknown()).safeParse(input).success;
};

export class ServerSideError extends Error {
  readonly _tag = 'ServerSideError';

  constructor(message: string) {
    super(message);
  }
}

export class BadRequestError extends Error {
  readonly _tag = 'BadRequestError';

  statusCode: number;
  responseBody: unknown;

  constructor(message: string, statusCode: number, responseBody: unknown) {
    super(message);
    this.statusCode = statusCode;
    this.responseBody = responseBody;
  }
}

/**
 * Represents when a request fails because the user making the request does not have the correct
 * team permissions
 */
export class UnauthorisedError extends Error {
  readonly statusCode = 403;
  readonly name = 'UnauthorisedError';

  constructor(public readonly unauthorisedTeams: { id: TeamId; name: string }[]) {
    super('Manager does not have required permissions to edit these teams');
  }
}

export const isConflictedResourceError = (e: unknown): boolean => {
  return e instanceof BadRequestError && e.statusCode === 409;
};

export class SurfboardEmployeeWrongOrgError extends Error {
  readonly _tag = 'SurfboardEmployeeWrongOrgError';

  statusCode: number;

  constructor() {
    super('Surfboard employee logged into wrong org');
  }
}

export class NotFoundError extends Error {
  readonly _tag = 'NotFoundError';

  statusCode: number;

  constructor() {
    super('404 not found error');
  }
}

type RetryOptions = Pick<QueryOptions, 'retry' | 'retryDelay'>;

const retryOptions: RetryOptions = {
  retry: (failureCount, error) => {
    // return value determines whether react query retries again
    if (error instanceof BadRequestError || error instanceof ServerSideError) {
      return false;
    }
    return failureCount < 2;
  },
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      ...retryOptions,
    },
  },
};

export const constructServerUrl = (path: string): URL => {
  const host = import.meta.env.VITE_SERVER_HOST ?? location.host;
  return new URL(path, `${location.protocol}//${host}`);
};

export const useRequest = () => {
  // this needs to return a closure to prevent rules of hooks errors
  // because useOrgId and useAuth0 can't be called inside useQuery/useMutation
  const orgId = useOrgId();
  const surferId = useSurferId();
  const managerId = useManagerId();
  const isSurfboardEmployee = useIsSurfboardEmployee();
  const roles = useRoles();
  const _debugInfo = { orgId, surferId, managerId, isSurfboardEmployee, roles };
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const setShouldHardNavigate = useSetShouldHardNavigate();

  const getHeaders = async () => {
    return {
      'Content-type': 'application/json',
      Authorization: isAuthenticated ? `Bearer ${await getAccessTokenSilently()}` : '',
      'x-commit-hash': __COMMIT_HASH__,
      'x-session-id': SESSION_ID,
    };
  };

  return async <T extends z.ZodTypeAny>(
    path: string,
    schema: T,
    options?: RequestOptions,
  ): Promise<Unpaginated<z.output<T>>> => {
    const reqOptions = options ?? { method: 'GET' };
    const { version, searchParams, useExactPathProvided, ...fetchOptions } = reqOptions;

    const headers = await getHeaders();

    const initialUrl = constructServerUrl(
      useExactPathProvided ? path : `/${version ?? 'v0'}/organizations/${orgId}${path}`,
    );

    if (searchParams !== undefined) {
      Object.entries(searchParams).forEach(([key, val]) => {
        initialUrl.searchParams.set(key, val);
      });
    }

    // url will be re-assigned to nullable next page url if results are paginated
    let url: string | null = initialUrl.toString();

    const paginatedResultAccumulator: unknown[] = [];

    while (url) {
      // random id used to correlate client requests with server's request handling.
      const requestId = generateRequestId();

      const response = await fetch(url, {
        headers: {
          ...headers,
          'x-request-id': requestId,
        },
        ...fetchOptions,
      });

      reloadIfBundleIsStale(response, setShouldHardNavigate);

      if (!response.ok) {
        const error = await errorFromResponse(response, { path, isSurfboardEmployee });

        if (shouldAlertForError(error)) {
          Sentry.captureException(error, {
            extra: {
              path,
              requestId,
              statusCode: response.status,
              ..._debugInfo,
            },
          });
        }

        // needs to be thrown for react-query to register there was an error
        throw error;
      }

      let jsonParsingError: unknown;

      const responseRaw = await response.text();
      let data: any;
      try {
        data = JSON.parse(responseRaw);

        if (options?.transformKeys ?? version !== 'v0') {
          data = toCamel(data);
        }
      } catch (err) {
        jsonParsingError = err;
      }

      const result = schema.safeParse(data);

      if (!result.success) {
        let error = new Error('Client-side zod validation error');
        if (JSON.stringify(data) === '{}') {
          // Treating this validation error differently so we can silence it in Sentry
          error = new Error(
            `Weird error where the body received is empty. We don't know why, but doesn't seem to affect customers`,
          );
        }

        Sentry.captureException(error, {
          extra: {
            ..._debugInfo,
            validationErrors: JSON.stringify(result.error.errors, null, 2),
            jsonParsingError,
            data,
            statusCode: response.status,
            contentType: response.headers.get('content-type'),
            path,
            requestId,
            responseRaw,
          },
        });

        // thrown to be registered react-query
        throw error;
      }

      const parsed = result.data;

      if (!isPaginated<T>(parsed)) {
        return parsed as Unpaginated<T>;
      }

      const pageOfResults = parsed.results;
      const nextPageUrl = parsed.pageInfo.next;

      paginatedResultAccumulator.push(...pageOfResults);

      url = nextPageUrl;
    }

    return paginatedResultAccumulator as Unpaginated<T>;
  };
};

const UnauthorisedResponseBodySchema = z.object({
  unauthorisedTeams: z.object({ id: TeamIdSchema, name: z.string() }).array(),
});

const errorFromResponse = async (
  response: Response,
  context: { path: string; isSurfboardEmployee: boolean },
): Promise<Error> => {
  const responseBody = await response.json().catch(_ => undefined);

  if (response.status >= 500) {
    if (responseBody?.error === 'APPLY_IN_PROGRESS') {
      return new ServerSideError('APPLY_IN_PROGRESS');
    }

    return new ServerSideError(`${response.status} response from ${context.path}`);
  }

  if (response.status === 403) {
    const maybeUnauthorisedResponseBody = UnauthorisedResponseBodySchema.safeParse(responseBody);
    if (maybeUnauthorisedResponseBody.success) {
      return new UnauthorisedError(maybeUnauthorisedResponseBody.data.unauthorisedTeams);
    }

    if (context.isSurfboardEmployee) {
      return new SurfboardEmployeeWrongOrgError();
    }
  }

  if (response.status === 404) {
    return new NotFoundError();
  }

  if (response.status >= 400) {
    const errorMessage: string =
      {
        403: '403 FORBIDDEN error - could be surfer requesting manager endpoint',
        401: '401 unauthorised error',
      }[response.status] ?? 'Bad request error';

    return new BadRequestError(errorMessage, response.status, responseBody);
  }

  return new Error('Network response was not ok');
};

const shouldAlertForError = (err: Error): boolean => {
  if (err instanceof BadRequestError && err.statusCode === 409) {
    // we expect 409 errors caused by two managers are editing the same thing
    return false;
  }

  if (err instanceof SurfboardEmployeeWrongOrgError) {
    // we expect to see these when Surfboard employees have different orgs in different tabs
    return false;
  }

  if (err instanceof NotFoundError) {
    // 404s can be an acceptable response when requesting a resource that doesn't exist (eg: getting surfer feedback for a given day)
    return false;
  }

  return true;
};
