import z from 'zod';

import { ColorSchema } from './colors';
import { ShiftTemplateRefSchema } from './ids';
import { pagedResultsSchema } from './pagination';
import { NaiveIntervalSchema, dateString } from './time';
import { positiveInteger } from './utils';

export const ShiftTemplateConflictSchema = z.enum(['standard', 'fill']);
export type ShiftTemplateConflictType = z.infer<typeof ShiftTemplateConflictSchema>;

export const ShiftTemplateSchema = z.object({
  name: z.string().min(1),
  interval: NaiveIntervalSchema,
  breaks: z.object({ duration: positiveInteger }).array(),
  color: ColorSchema,
  state: z.enum(['active', 'archived']),
  conflictType: ShiftTemplateConflictSchema.optional(),
});

export const PersistedShiftTemplateSchema = ShiftTemplateSchema.merge(
  ShiftTemplateRefSchema,
).extend({
  createdAt: dateString,
});

/**
 * ShiftTemplate captures the start time and duration of a shift
 * prior to its application to a specific day
 */
export type ShiftTemplate = z.infer<typeof ShiftTemplateSchema>;

export type PersistedShiftTemplate = z.infer<typeof PersistedShiftTemplateSchema>;

export const GetShiftTemplatesRespSchema = pagedResultsSchema(PersistedShiftTemplateSchema);
export type GetShiftTemplatesResp = z.infer<typeof GetShiftTemplatesRespSchema>;

export const CreateShiftTemplateReqSchema = ShiftTemplateSchema;
export type CreateShiftTemplateReq = z.infer<typeof CreateShiftTemplateReqSchema>;

export const CreateShiftTemplateRespSchema = ShiftTemplateRefSchema;
export type CreateShiftTemplateResp = z.infer<typeof CreateShiftTemplateRespSchema>;

export const UpdateShiftTemplateReqSchema = ShiftTemplateSchema;
export type UpdateShiftTemplateReq = z.infer<typeof UpdateShiftTemplateReqSchema>;

export const UpdateShiftTemplateRespSchema = ShiftTemplateRefSchema;
export type UpdateShiftTemplateResp = z.infer<typeof UpdateShiftTemplateRespSchema>;
