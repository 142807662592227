import { create } from 'zustand';
import { combine } from 'zustand/middleware';

// Not exported so no one can subcribe to the entire store
const useNavigationConfigStore = create(
  combine({ shouldHardNavigate: false }, set => ({
    setShouldHardNavigate: (newValue: boolean) => set(_state => ({ shouldHardNavigate: newValue })),
  })),
);

export const useSetShouldHardNavigate = () =>
  useNavigationConfigStore(state => state.setShouldHardNavigate);
export const useShouldHardNavigate = () =>
  useNavigationConfigStore(state => state.shouldHardNavigate);
