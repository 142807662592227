import { z } from 'zod';

import { StreamIdSchema } from './ids';
import { dateString } from './time';
import { nonnegativeInteger } from './utils';

const MetricsGroupedByChannelSchema = z.object({
  date: dateString,
  channelName: z.string().min(1),
  value: nonnegativeInteger,
  streamId: StreamIdSchema,
});

export type StaffingMetric = z.infer<typeof MetricsGroupedByChannelSchema>;

export const GetStaffingRespSchema = MetricsGroupedByChannelSchema.array();
