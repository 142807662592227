import { SystemStyleObject } from '@chakra-ui/theme-tools';

const Checkbox: SystemStyleObject = {
  baseStyle: {
    control: {
      _focus: {
        boxShadow: '0 0 0 3px var(--chakra-colors-purple-20)',
      },
      _checked: {
        bg: 'purple.30',
        borderColor: 'purple.30',
        _hover: {
          bg: 'purple.40',
        },
        _focus: {
          boxShadow: '0 0 0 3px var(--chakra-colors-purple-20)',
        },
      },
    },
  },
};

export default Checkbox;
