// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClassItems = (string | ClassItems[] | { [k: string]: any } | false | undefined | null)[];

/**
 *  @example
 *
 *  // returns "one three five six"
 *  cx("one", "two", false, {three: true, four: false}, ["five", false, "six"])
 */
export default function cx(...classItems: ClassItems): string {
  return (
    classItems
      .map(item => {
        if (Array.isArray(item)) return cx(...item);
        if (typeof item === 'object' && item !== null)
          return Object.entries(item)
            .filter(([_, v]) => v)
            .map(([k, _]) => k)
            .join(' ');
        return item;
      })
      .filter(item => item && typeof item === 'string')
      .join(' ') || ''
  );
}
