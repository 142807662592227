import { z } from 'zod';

import { SurferAvailabilityIdSchema, SurferIdSchema } from './ids';
import { dateString } from './time';

export const SurferAvailabilitySchema = z.object({
  start: dateString,
  end: dateString,
});

export type SurferAvailability = z.infer<typeof SurferAvailabilitySchema>;

export const PersistedSurferAvailabilitySchema = SurferAvailabilitySchema.extend({
  id: SurferAvailabilityIdSchema,
  surferId: SurferIdSchema,
});
export type PersistedSurferAvailability = z.infer<typeof PersistedSurferAvailabilitySchema>;

export const GetAvailabilityRespSchema = PersistedSurferAvailabilitySchema.array();
export type GetAvailabilityResp = z.infer<typeof GetAvailabilityRespSchema>;

export const CreateSurferAvailabilityReqSchema = SurferAvailabilitySchema;
export type CreateSurferAvailabilityReq = z.infer<typeof CreateSurferAvailabilityReqSchema>;

export const UpdateSurferAvailabilityReqSchema = PersistedSurferAvailabilitySchema;
export type UpdateSurferAvailabilityReq = z.infer<typeof UpdateSurferAvailabilityReqSchema>;
