import { differenceInHours } from 'date-fns';
import z from 'zod';

import { isTimezoneIana } from './refinements/timezone';
import { nonnegativeInteger } from './utils';

export const dateString = z.preprocess(arg => {
  return typeof arg == 'string' || arg instanceof Date ? new Date(arg) : undefined;
}, z.date());

export const WeekdaySchema = z.enum([
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]);
export type Weekday = z.infer<typeof WeekdaySchema>;

export const TimezoneIanaSchema = z
  .string()
  .refine(isTimezoneIana, { message: `value is not a valid IANA timezone` })
  .brand<'TimezoneIana'>();
export type TimezoneIana = z.infer<typeof TimezoneIanaSchema>;

export const IntervalSchema = z
  .object({
    start: dateString,
    end: dateString,
  })
  .refine(interval => interval.start.valueOf() <= interval.end.valueOf());

export type Interval = z.infer<typeof IntervalSchema>;

export const PartialIntervalSchema = z
  .object({
    start: dateString.optional(),
    end: dateString.optional(),
  })
  .refine(({ start, end }) => !(start && end && start.valueOf() > end.valueOf()));

export type PartialInterval = z.infer<typeof PartialIntervalSchema>;

/**
 * A duration represented as minutes.
 */
export const DurationSchema = nonnegativeInteger;
export type Duration = z.infer<typeof DurationSchema>;

export const NaiveTimeSchema = z.number().int();

/**
 * A relative, possibly negative, time that is expressed as minutes from a not
 * yet defined point in time.
 */
export type NaiveTime = z.infer<typeof NaiveTimeSchema>;

/**
 * An interval representing a period between two naive times.
 */
export const NaiveIntervalSchema = z.object({
  /**
   * Start of the naive interval.
   */
  start: NaiveTimeSchema,
  /**
   * End of the naive interval.
   */
  end: NaiveTimeSchema,
  timezone: TimezoneIanaSchema,
});
export type NaiveInterval = z.infer<typeof NaiveIntervalSchema>;

export const LimitedIntervalSchema = (limit: { maxHours: number }) =>
  z.object({ startDate: dateString, endDate: dateString }).refine(
    ({ startDate, endDate }) => {
      return differenceInHours(endDate, startDate) <= limit.maxHours;
    },
    { message: `Requested duration cannot be larger than ${limit.maxHours} hours` },
  );

export const DurationIntervalSchema = z
  .object({
    min: nonnegativeInteger.optional(),
    max: nonnegativeInteger.optional(),
  })
  .refine(
    ({ min, max }) => (min ?? 0) <= (max ?? Infinity),
    'minimum must be smaller than the maximum',
  );
export type DurationInterval = z.infer<typeof DurationIntervalSchema>;
