import { SystemStyleObject } from '@chakra-ui/theme-tools';

const FormLabel: SystemStyleObject = {
  baseStyle: {
    color: 'gray.40',
    fontSize: 'f2',
    marginBottom: '0.35em',
    fontWeight: 500,
    lineHeight: 'paragraph',
  },
};

export default FormLabel;
