import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _focus: {
        boxShadow: '0 0 0 3px var(--chakra-colors-purple-20)',
      },
      _checked: {
        bg: 'purple.30',
        borderColor: 'purple.30',
        _hover: {
          bg: 'purple.40',
        },
        _focus: {
          boxShadow: '0 0 0 3px var(--chakra-colors-purple-20)',
        },
      },
    },
  },
  sizes: {
    md: {
      control: { w: 4, h: 4 },
      label: { fontSize: '14px' },
    },
  },
};

export default Radio;
