import { z } from 'zod';

import { TaskIdSchema } from './ids';
import { nonnegativeInteger } from './utils';

const TaskInsightSchema = z.object({
  taskId: TaskIdSchema,
  totalMinutes: nonnegativeInteger,
  isServiceActivity: z.boolean(),
  isBreak: z.boolean(),
});
export type TaskInsight = z.infer<typeof TaskInsightSchema>;

const RequiredServiceSchema = z.object({
  tickets: nonnegativeInteger,
  staffingMinutes: nonnegativeInteger,
});

export const InsightsPayloadSchema = z.object({
  scheduledShiftMinutes: nonnegativeInteger,
  scheduledShiftMinutesShrinkageDueToTimeOff: nonnegativeInteger,
  workedShiftMinutes: nonnegativeInteger,
  taskInsights: z.array(TaskInsightSchema),
  blockedTimeMinutes: nonnegativeInteger,
  totalUnscheduledMinutes: nonnegativeInteger,
  requiredService: RequiredServiceSchema,
});
export type InsightsPayload = z.infer<typeof InsightsPayloadSchema>;

export type AggregateScheduleData = Pick<
  InsightsPayload,
  'workedShiftMinutes' | 'taskInsights' | 'blockedTimeMinutes' | 'totalUnscheduledMinutes'
>;
