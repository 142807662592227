import { z } from 'zod';

import { SurferIdSchema } from './ids';

export const OAuthAuthorizeUrlSchema = z.object({
  url: z.string().nullable(),
});

export type OAuthAuthorizeUrl = z.infer<typeof OAuthAuthorizeUrlSchema>;

export const CompleteOAuthFlowSchema = z.object({
  surferId: SurferIdSchema,
  code: z.string(),
  scope: z.string().array(),
});
export type CompleteOAuthFlow = z.infer<typeof CompleteOAuthFlowSchema>;
