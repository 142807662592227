import z, { AnyZodObject, ZodType } from 'zod';

export const positiveInteger = z.number().int().positive();
export const nonnegativeInteger = z.number().int().nonnegative();

export const numberOrString = z.preprocess(a => Number(a), z.number());
export const nonNegativeStringOrNumber = z.preprocess(a => Number(a), z.number().nonnegative());
export const positiveIntegerStringOrNumber = z.preprocess(
  a => Number(a),
  z.number().int().positive(),
);

export const positiveNumber = z.number().positive();
export const nonnegativeNumber = z.number().nonnegative();

export const trimmedString = z.string().trim().min(1);

export const booleanString = z
  .union([z.literal('true'), z.literal('false')])
  .transform(s => s === 'true');

export const RefSchema = z.object({
  id: nonnegativeInteger,
  version: nonnegativeInteger,
});
export type Ref = z.infer<typeof RefSchema>;

export type AtLeastOne<T> = [T, ...T[]];

/**
 * used to make a zod schema optional while also accepting null values (but coercing them into undefined)
 * e.g. convertNullToUndefined(z.string()).parse(null) => undefined
 */
export const convertNullToUndefined = <T>(schema: ZodType<T>) =>
  schema.nullish().transform(v => (v === null ? undefined : v));

/**
 * @param keyFallbackFns - an object where each key is a field to derive,
 *  and the value is a function to derive that key given the whole raw object as an input
 */
export const withFallbackValuesForKeys = <Schema extends AnyZodObject>(
  keyFallbackFns: Partial<Record<keyof z.infer<Schema>, (input: any) => any>>,
  schema: Schema,
) => {
  return z.preprocess((input: any) => {
    const keysToMaybeOverwrite = Object.entries(keyFallbackFns).reduce<Record<string, any>>(
      (acc, [currentKey, currentFallbackFn]) => {
        acc[currentKey] = input[currentKey] ?? currentFallbackFn?.(input);
        return acc;
      },
      {},
    );
    return { ...input, ...keysToMaybeOverwrite };
  }, schema);
};

export const RatioSchema = z.number().brand<'Ratio'>();
export type Ratio = z.infer<typeof RatioSchema>;

export const arrayQueryPreprocessor = (val: unknown) => {
  const valString = val as string;

  if (valString.length === 0) {
    return [];
  }

  return valString.split(',');
};
