import { Heading as ChakraHeading } from '@chakra-ui/react';

import { BaseTextProps } from '../types';

const Heading: React.FC<React.PropsWithChildren<BaseTextProps>> = ({ children, ...rest }) => (
  <ChakraHeading {...rest} lineHeight="heading">
    {children}
  </ChakraHeading>
);

export default Heading;
