import { SystemStyleObject, cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

const Tooltip: SystemStyleObject = {
  baseStyle: {
    padding: '8px',
    borderRadius: '4px',
    textAlign: 'center',
    lineHeight: '145%',
  },
  defaultProps: {
    variant: 'dark',
  },
  variants: {
    dark: {
      bg: 'gray.40',
      color: 'white',
      [$arrowBg.variable]: 'colors.gray.40',
    },
    light: {
      bg: 'white',
      color: 'gray.40',
      [$arrowBg.variable]: 'white',
    },
  },
};

export default Tooltip;
