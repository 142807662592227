import { SystemStyleObject } from '@chakra-ui/theme-tools';

const colors: SystemStyleObject = {
  white: '#FFFFFF',
  gray: {
    5: '#FAFAFF',
    10: '#F7F7FA',
    20: '#E7E7EA',
    30: '#929296',
    40: '#646468',
    50: '#27262B',
  },
  purple: {
    10: '#F3F3FF',
    20: '#BCB1FF',
    30: '#562AD4', // primary
    40: '#3B1A97',
    50: '#221155',
  },
  yellow: {
    10: '#FFFAEA',
    20: '#FFEFB7',
    30: '#FFD873',
    40: '#FFC700', // primary
    50: '#665000',
  },
  teal: {
    10: '#EBFBF7',
    20: '#C5E9DE',
    30: '#55BCB6',
    40: '#297D7D',
    50: '#1D4947',
  },
  green: {
    10: '#F1F9F0',
    20: '#BAE2B6',
    30: '#74C56D',
    50: '#21491D',
  },
  orange: {
    10: '#FFF5EB',
    20: '#FFCB99',
    30: '#FF9C3C',
    50: '#663200',
  },
  pink: {
    10: '#FFEBF5',
    20: '#FFADD8',
    30: '#F769B2',
    50: '#660035',
  },
  berry: {
    10: '#F7EBFF',
    20: '#D899FF',
    30: '#AD38F5',
    50: '#3F0066',
  },
  olive: {
    10: '#F9F9F0',
    20: '#E2E0B6',
    30: '#CAC779',
    50: '#49481D',
  },
  royalBlue: {
    10: '#EBEBFF',
    20: '#999DFF',
    30: '#3840FF',
    50: '#000466',
  },
  blue: {
    10: '#EBF3FF',
    20: '#99C3FF',
    30: '#599DFF',
    50: '#002966',
  },
  beige: {
    10: '#F6F2EF',
    20: '#DAC9BE',
    30: '#BD9F8B',
    50: '#413025',
  },
  red: {
    10: '#FDE7E7',
    20: '#F7A1A1',
    30: '#F37070',
    50: '#5E0808',
    error: '#E31E03',
  },
  inputBorderColor: '#E7E7E7',
  success: {
    30: '#428500',
    40: '#264D00',
  },
  error: {
    30: '#E31E03',
    40: '#971402',
  },
};

export default colors;
