import { SystemStyleObject } from '@chakra-ui/theme-tools';

const Link: SystemStyleObject = {
  baseStyle: {
    color: 'gray.40',
    fontSize: 'f2',
    fontWeight: 'semibold',
    padding: '0.5em',
  },
  _hover: {
    color: 'purple.30',
    textDecoration: 'none',
  },
  _active: {
    color: 'purple.30',
  },
  variants: {
    menu: {
      borderRadius: '5px',
      justifyContent: 'left',
      display: 'flex',
      width: '100%',
      marginBottom: 2,
      fontSize: 'f2',
    },
  },
};

export default Link;
