import countBy from 'lodash.countby';
import groupBy from 'lodash.groupby';
import uniq from 'lodash.uniq';
import z from 'zod';
import { RefinementCtx, ZodIssueCode } from 'zod';

import { PlanRefSchema, RoundRobinIdSchema, ShiftTemplateRefSchema, SurferIdSchema } from './ids';
import { pagedResultsSchema } from './pagination';
import { BlockSchema, BreakSchema } from './schedule';
import { DurationSchema, IntervalSchema, NaiveIntervalSchema, dateString } from './time';
import { nonnegativeInteger } from './utils';

export const PlannedShiftSchema = z.object({
  /**
   * When the shift will be worked on.
   */
  interval: NaiveIntervalSchema,
  /**
   * Describes all the breaks allowed during the shift.
   */
  breaks: z.array(BreakSchema),
  /**
   * Reference for the shift template used to create this shift in the plan.
   */
  shiftTemplateRef: ShiftTemplateRefSchema,
});

export type PlannedShift = z.infer<typeof PlannedShiftSchema>;

export const PersistedPlannedShiftIdSchema = z.string().min(1).brand<'PersistedPlannedShiftId'>();
export type PersistedPlannedShiftId = z.infer<typeof PersistedPlannedShiftIdSchema>;

/**
 * A series of shifts that are worked on by a specific group
 * of surfers within a shift pattern.
 */
export const ShiftPatternGroupSchema = z.object({
  /**
   * All the surfer assigned to the shifts defined by this pattern.
   */
  assignees: z.array(SurferIdSchema),
  /**
   * All the shift that will be repeated periodically when
   * apply this pattern.
   */
  plannedShifts: z.array(PlannedShiftSchema),
});
export type ShiftPatternGroup = z.infer<typeof ShiftPatternGroupSchema>;

/**
 * A shift pattern defines where a series of shift are allocated
 * in the schedule and how they repeat over time.
 */
export const ShiftPatternSchema = z.object({
  /**
   * Human-readable name assigned to the pattern.
   */
  name: z.string().min(1),
  /**
   * A series of shifts that are worked on by a specific group
   * of surfers.
   */
  groups: z.array(ShiftPatternGroupSchema),
  /**
   * How long the pattern last in seconds before it starts repeating itself.
   * i.e. weekly, daily, fortnightly
   */
  duration: DurationSchema,
});
export type ShiftPattern = z.infer<typeof ShiftPatternSchema>;

export const PlannedRoundRobinShiftSchema = PlannedShiftSchema.merge(
  z.object({
    timeInLieu: z.array(NaiveIntervalSchema),
    numberOfSurfers: z.number().min(1),
    /**
     * An ID that stays consistent between round robin changes
     * i.e. ULID
     */
    plannedRoundRobinShiftId: PersistedPlannedShiftIdSchema,
  }),
);
export type PlannedRoundRobinShift = z.infer<typeof PlannedRoundRobinShiftSchema>;

const BaseRoundRobinRuleSchema = z.object({
  order: z.number(),
});

export const RoundRobinRuleShiftGroupSchema = BaseRoundRobinRuleSchema.extend({
  type: z.literal('shift-group'),
  settings: z.object({
    mainShift: PersistedPlannedShiftIdSchema,
    shiftsToGroup: z.array(PersistedPlannedShiftIdSchema).min(1, 'Select at least one shift'),
  }),
});

export const RoundRobinRuleSchema = RoundRobinRuleShiftGroupSchema; // extend to more when necessary

export type RoundRobinRule = z.infer<typeof RoundRobinRuleSchema>;

export const RoundRobinSchema = z.object({
  roundRobinId: RoundRobinIdSchema,
  name: z.string().min(1),
  cycleDuration: DurationSchema,
  plannedShifts: z.array(PlannedRoundRobinShiftSchema),
  orderedAssignees: z.array(SurferIdSchema),
  rules: z.array(RoundRobinRuleSchema),
});
export type RoundRobin = z.infer<typeof RoundRobinSchema>;

// Validate the main structure of a plan.
// 17/08/23 - constraint removed: surfers can't be in more than one round robin
export const refinePlan = (plan: Plan, ctx: RefinementCtx) => {
  // Constraint #1: surfers can’t be in more than 1 pattern
  const assigneePatterns = groupBy(
    plan.shiftPatterns.flatMap(pattern =>
      // find all surfer ids fo each pattern deduping and return a tuple
      // containing surfer id and pattern name
      uniq(pattern.groups.flatMap(group => group.assignees)).map(assignee => [
        assignee,
        pattern.name,
      ]),
    ),
    ([assignee, _]) => assignee,
  );

  Object.keys(assigneePatterns).forEach(assignee => {
    const patterns = assigneePatterns[assignee];

    if (patterns.length > 1) {
      const plans = patterns
        .sort()
        .map(([_, name]) => name)
        .join(', ');

      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: `The surfer ${assignee} is present in more than one pattern: ${plans}`,
      });
    }
  });

  // Constraint #2: surfers can’t be in more than 1 group
  plan.shiftPatterns.forEach(pattern => {
    // count how many times a surfer has been assigned to a group
    const groupsCount = countBy(pattern.groups.flatMap(group => group.assignees));

    Object.entries(groupsCount).forEach(([assignee, count]) => {
      if (count > 1) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: `The surfer ${assignee} is present in ${count} different groups`,
        });
      }
    });
  });

  plan.roundRobins.forEach(roundRobin => {
    // Constraint #3: round robin shifts can't have more surfers assigned than
    // the total number of surfers in the round robin
    const totalAssignees = roundRobin.orderedAssignees.length;

    roundRobin.plannedShifts.forEach(shift => {
      if (shift.numberOfSurfers > totalAssignees) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: `A shift in round robin ${roundRobin.roundRobinId} requires ${shift.numberOfSurfers} surfers but there are only ${totalAssignees} assignees in the round robin`,
        });
      }
    });
  });
};

/**
 * This is the core structure of a plan where we define the logic
 * used to generate shifts.
 *
 * Schemas cannot be extended/merged after refine. This schema is used to
 * create new schema without repeating the schema definition.
 * When using this schema the refine function needs to be added for each new schema.
 */
const PlanSchemaBase = z.object({
  /**
   * Human-readable name assigned to the plan.
   */
  name: z.string().min(1),
  /**
   * The plan's state:
   *
   * * `active`: the plan is visible and can be used by the managers.
   * * `archived`: the plan has been soft-deleted. Its configuration will
   * be kept for historical reference but won't be displayed to managers anymore.
   */
  state: z.enum(['active', 'archived']),
  /**
   * URL for Unsplash image displayed on plan components.
   */
  imageUrl: z.string().url(),
  /**
   * The emoji displayed on plan components.
   */
  emoji: z.string().min(1),
  /**
   * All shift patterns that compose the plan.
   */
  shiftPatterns: z.array(ShiftPatternSchema),
  /**
   * Round robins included in the plan
   */
  roundRobins: z.array(RoundRobinSchema),
});

const PersistedPlanBase = PlanSchemaBase.merge(PlanRefSchema).extend({
  createdAt: dateString,
});

export const PlanSchema = PlanSchemaBase.superRefine(refinePlan);

export type Plan = z.infer<typeof PlanSchema>;

export const PersistedPlanSchema = PersistedPlanBase.superRefine(refinePlan);

export type PersistedPlan = z.infer<typeof PersistedPlanSchema>;

// Note we are using the schema without `refinePlan` when getting plans as we
// only want this validation to run when creating / updating plans - it should
// not prevent us showing data on FE
export const GetPlansRespSchema = pagedResultsSchema(PersistedPlanBase);
export type GetPlansResp = z.infer<typeof GetPlansRespSchema>;

export const CreatePlanReqSchema = PlanSchema;
export type CreatePlanReq = z.infer<typeof CreatePlanReqSchema>;

export const UpdatePlanReqSchema = PlanSchema;
export type UpdatePlanReq = z.infer<typeof UpdatePlanReqSchema>;

export const CreatePlanRespSchema = z.object({
  id: nonnegativeInteger,
  version: nonnegativeInteger,
});
export type CreatePlanResp = z.infer<typeof CreatePlanRespSchema>;

export const UpdatePlanRespSchema = z.object({
  id: nonnegativeInteger,
  version: nonnegativeInteger,
});
export type UpdatePlanResp = z.infer<typeof UpdatePlanRespSchema>;

export const GetPlanRespSchema = PersistedPlanBase;
export type GetPlanResp = z.infer<typeof GetPlanRespSchema>;

export const PreviewPlanRespSchema = pagedResultsSchema(BlockSchema);
export type PreviewPlanResp = z.infer<typeof PreviewPlanRespSchema>;

/**
 * Configuration that will instruct the planner on how to generate the schedule view
 * from a plan.
 */
const GenerateOptionsSchema = z.object({
  /**
   * Generates a schedule view for this time range.
   */
  interval: IntervalSchema,
  /**
   * The plan used to generate the blocks.
   */
  planRef: PlanRefSchema,
  /**
   * Start generating blocks from planned shift placed after the given
   * amount of minutes. The offset applies is used only to generate pattern
   * blocks and round robin won't be impacted by it.
   *
   * This allows managers to apply a long plan starting from an arbitrary week.
   *
   * Example:
   *
   * If we have a plan with a single pattern that has duration 2 weeks and
   * two planned shift:
   *
   *  * week 1: Mon 9am-5pm
   *  * week 2: Tue 10am-8pm
   *
   * when we apply this from Mon 1st Aug 2022 to Mon 14th Aug 2022 with offset 1 week (10080 minutes),
   * it will generate two shifts:
   *
   *  * Tue 2nd Aug 2022 10am-8pm
   *  * Mon 8th Aug 2022 9am-5pm
   *
   * instead of what we would get with no offset
   *
   *  * Mon 1st Aug 2022 9am-5pm
   *  * Tue 9th Aug 2022 10am-8pm
   */
  offset: DurationSchema.optional(),
});
export type GenerateOptions = z.infer<typeof GenerateOptionsSchema>;
