import { useAuth0 } from '@auth0/auth0-react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { ReactNode, createContext, useContext } from 'react';

const MixPanelContext = createContext({} as Mixpanel);
const _useMixPanel = () => useContext(MixPanelContext);

const AnalyticsProvider = ({ children }: { children: ReactNode; token?: string }) => {
  const { user, isAuthenticated } = useAuth0();

  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);
    mixpanel.set_config({ debug: import.meta.env.VITE_MIXPANEL_DEBUG });

    if (isAuthenticated && user?.email) {
      // user_<id>@domain
      mixpanel.identify(`user_${user?.sub}@${user?.email.split('@')[1]}`);
    }
  }

  return <MixPanelContext.Provider value={mixpanel}>{children}</MixPanelContext.Provider>;
};

export { _useMixPanel };
export default AnalyticsProvider;
