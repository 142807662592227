import { z } from 'zod';

import { integerString } from './ids';
import { dateString } from './time';

const ForecastAdjustmentStreamSchema = z.object({
  adjustmentFactor: z.number(),
  streamId: z.number(),
});

export const ForecastAdjustmentSchema = z.object({
  name: z.string().min(1),
  from: dateString,
  to: dateString,
  streams: ForecastAdjustmentStreamSchema.array(),
  // ^ there are some forecast adjustments in prod which have no associated streams
});
export type ForecastAdjustment = z.infer<typeof ForecastAdjustmentSchema>;

const PersistedForecastAdjustmentSchema = ForecastAdjustmentSchema.extend({ id: integerString });
export type PersistedForecastAdjustment = z.infer<typeof PersistedForecastAdjustmentSchema>;

export const GetForecastAdjustmentsRespSchema = z.array(PersistedForecastAdjustmentSchema);
export type GetForecastAdjustmentsResp = z.infer<typeof GetForecastAdjustmentsRespSchema>;

export const CreateForecastAdjustmentRespSchema = z.object({ id: z.number() });
export type CreateForecastAdjustmentResp = z.infer<typeof CreateForecastAdjustmentRespSchema>;
