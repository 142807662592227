import { z } from 'zod';

import { dateString } from './time';
import { positiveInteger } from './utils';

export const RegisterFeedbackReqSchema = z.object({
  shiftDay: dateString,
  value: z.string().min(1),
  comment: z.string().optional(),
});
export type RegisterFeedbackReq = z.infer<typeof RegisterFeedbackReqSchema>;

export const GetFeedbackQuerySchema = z.object({
  shiftDay: dateString,
});
export type GetFeedbackQuery = z.infer<typeof GetFeedbackQuerySchema>;

export const GetFeedbackRespSchema = z.object({
  shiftDay: dateString,
  value: z.string().min(1).optional(),
  comment: z.string().optional(),
});
export type GetFeedbackResp = z.infer<typeof GetFeedbackRespSchema>;

export const SummaryRespSchema = z.object({
  results: z.record(z.string(), positiveInteger),
  comments: z.array(z.string()),
});
export type SummaryResp = z.infer<typeof SummaryRespSchema>;

export const GetFeedbackSummaryQuerySchema = z
  .object({ start: dateString, end: dateString })
  .refine(
    ({ start, end }) => {
      return start < end;
    },
    { message: 'End date must be after start date.' },
  );
export type GetFeedbackSummary = z.infer<typeof GetFeedbackSummaryQuerySchema>;
