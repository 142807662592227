import { Text as ChakraText } from '@chakra-ui/react';
import { FC } from 'react';

import cx from '../../lib/cx';
import { BaseTextProps } from '../types';

export const Text: FC<React.PropsWithChildren<BaseTextProps>> = ({
  children,
  color = 'gray.50',
  className = '',
  size = 'f2',
  weight = 'normal',
  spacing,
  ...rest
}) => {
  // TODO: JM / remove when the spacing tokens are merged
  const _ = 'Text';
  const classes = cx(_, className, spacing);

  return (
    <ChakraText
      fontSize={size}
      color={color}
      fontWeight={weight}
      lineHeight="paragraph"
      className={classes}
      {...rest}
    >
      {children}
    </ChakraText>
  );
};
