import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { GenericComponentProps } from '../types';

interface SectionProps extends GenericComponentProps, BoxProps {}

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({
  style,
  children,
  padding = 7,
  ...rest
}) => (
  <Box
    as="section"
    position="relative"
    padding={padding}
    css={{
      '&:last-child': {
        flexGrow: 1,
      },
    }}
    style={style}
    {...rest}
  >
    {children}
  </Box>
);
