import { weekViewStickyHeaderOptionsType } from '../pages/Schedule/useScheduleOptionsStore';

export type LocalStorageItems = {
  view: 'surfer' | 'manager';
  hasDeclinedSlackInstall: 'true';
  activitiesViewToolBarClosed: 'true';
  hideSurfersWithNoShiftsDayView: 'true' | 'false';
  hideAvailabilityWeekView: 'true' | 'false';
  hideCoverageWeekView: 'true' | 'false';
  weekViewStickyHeader: weekViewStickyHeaderOptionsType;
};

// NOTE: localStorage is not always supported (e.g. incognito mode)
// in these cases window.localStorage is null

export function setLocalStorageItem<
  T extends keyof LocalStorageItems,
  V extends LocalStorageItems[T],
>(item: T, value: V) {
  localStorage?.setItem(item, value);
}

export function getLocalStorageItem<
  T extends keyof LocalStorageItems,
  V extends LocalStorageItems[T],
>(item: T) {
  return (localStorage?.getItem(item) as V | null) ?? null;
}

export function clearLocalStorageItem<T extends keyof LocalStorageItems>(item: T) {
  localStorage?.removeItem(item);
}

export const LOCAL_STORAGE_KEYS = {
  FORECAST_PLAN_STORE: 'surfboard.forecast_plan.store',
  TICKET_GROUPS_FILTER: 'surfboard.schedule.ticketGroups.filter',
  ACTIVITY_PLANS_FILTER: 'surfboard.schedule.activityPlans.filter',
  SHOW_COVERAGE_BARS: 'surfboard.schedule.showCoverageBars.filter',
  SHARED_SURFERS_FILTER: 'surfboard.shared.surfers.filter',
  SURFERS_PERFORMANCE_FILTER: 'surfboard.performance.surfers.filter',
  WORKING_WITH_YOU_TEAMS_FILTER: 'surfboard.shift.workingWithYouTeams.filter',
  INSIGHTS_FTE: 'surfboard.insights.fte',
  TIMEZONE: 'surfboard.timezone',
  SCHEDULE_VIEW: 'surfboard.schedule.view',
  SCHEDULE_PUBLISHED: 'surfboard.schedule.published',
  APP_GLOBAL_OPTIONS_STORE: 'surfboard.appGlobalOptionsStore',
  SHIFT_TEMPLATE_FILTER: 'surfboard.schedule.shiftTemplate.filter',
  REAL_TIME_TEAMS_FILTER: 'surfboard.real-time.teams.filter',
};

export const getAndParseJSONFromLocalStorage = (
  localStorageKey: string,
  defaultReturnValue: any,
) => {
  try {
    const items = localStorage.getItem(localStorageKey);
    if (items) {
      return JSON.parse(items);
    }
  } catch {
    // prevent crash in case local storage is dirty
    return defaultReturnValue;
  }
  return defaultReturnValue;
};
