import { useAuth0 } from '@auth0/auth0-react';

export const useRoles = () => {
  const { user } = useAuth0();

  if (!user) {
    return [];
  }

  return user[import.meta.env.VITE_AUTH0_NAMESPACE + 'roles'];
};
