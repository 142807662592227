import { z } from 'zod';

import { ScheduledTaskSchema } from './activity';
import { ExtendedPaletteColorSchema } from './colors';
import { SurferIdSchema, TaskIdSchema, TeamIdSchema } from './ids';
import { TicketSessionWithProviderSchema, TimeOnTaskSurferStatusSchema } from './performance';
import { IntervalSchema, dateString } from './time';
import { PersistedShiftTimecardSchema } from './timecards';
import { arrayQueryPreprocessor } from './utils';

export const GetSurferDistributionPerTaskQuerySchema = z.object({
  startOfMinute: dateString,
  teamIds: z.undefined().or(z.preprocess(arrayQueryPreprocessor, TeamIdSchema.array())),
});

const TaskItemIdSchema = z.union([TaskIdSchema, z.enum(['breaks', 'events', 'unscheduled'])]);
export type TaskItemId = z.infer<typeof TaskItemIdSchema>;

const TaskItemSchema = z.object({
  id: TaskItemIdSchema,
  name: z.string(),
  emoji: z.string(),
  color: ExtendedPaletteColorSchema,
  value: z.number(),
  offsetValue: z.number(),
});
export type TaskItem = z.infer<typeof TaskItemSchema>;

const SurferDistributionTaskListSchema = z.object({
  name: z.string(), // should this be 'Service' | 'Non-service' | 'Other' ?
  totalSurfers: z.number(),
  items: z.array(TaskItemSchema),
});
export type SurferDistributionTaskList = z.infer<typeof SurferDistributionTaskListSchema>;

export const SurferDistributionPerTaskSchema = z.object({
  serviceList: SurferDistributionTaskListSchema,
  nonServiceList: SurferDistributionTaskListSchema,
  otherList: SurferDistributionTaskListSchema,
});
export type SurferDistributionPerTask = z.infer<typeof SurferDistributionPerTaskSchema>;

export const GetSurfersDistributionQuerySchema = z.object({
  startOfMinute: dateString,
  teamIds: z.undefined().or(z.preprocess(arrayQueryPreprocessor, TeamIdSchema.array())),
});

export const SurfersDistributionSchema = z.object({
  surferId: SurferIdSchema,
  currentTasks: ScheduledTaskSchema.array(),
  timecardsEnabled: z.boolean(),
  timecard: PersistedShiftTimecardSchema.optional(),
  shiftInterval: IntervalSchema,
  firstTicketSession: TicketSessionWithProviderSchema.optional(),
  firstScheduledTasks: ScheduledTaskSchema.array().optional(),
  status: TimeOnTaskSurferStatusSchema.optional(),
  isConnectedToSlack: z.boolean().optional(),
});
export type SurfersDistribution = z.infer<typeof SurfersDistributionSchema>;

export const GetSurfersDistributionRespSchema = z.object({
  results: SurfersDistributionSchema.array(),
});
export type GetSurfersDistributionResp = z.infer<typeof GetSurfersDistributionRespSchema>;
