import type { ComponentStyleConfig } from '@chakra-ui/theme';
import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { orient } from '@chakra-ui/theme-tools';

const Slider: ComponentStyleConfig = {
  baseStyle: {
    track: {
      borderRadius: '2px',
      bg: 'gray.20',
    },
    filledTrack: {
      bg: 'purple.30',
    },
  },
  sizes: {
    md: (props: StyleFunctionProps) => ({
      thumb: { w: '16px', h: '16px' },
      track: orient({
        orientation: props.orientation,
        horizontal: { h: '6px' },
        vertical: { w: '6px' },
      }),
    }),
  },
};

export default Slider;
