export const SM_BREAKPOINT_PX = 576;
export const MD_BREAKPOINT_PX = 992;
export const LG_BREAKPOINT_PX = 1200;

const breakpoints = {
  sm: `${SM_BREAKPOINT_PX}px`,
  md: `${MD_BREAKPOINT_PX}px`,
  lg: `${LG_BREAKPOINT_PX}px`,
};

export default breakpoints;
