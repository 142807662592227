import { z } from 'zod';

import {
  ManagerIdSchema,
  OrganizationIdSchema,
  ScheduleChangeRequestIdSchema,
  ShiftTemplateIdSchema,
  SurferIdSchema,
} from './ids';
import { pagedResultsSchema } from './pagination';
import { AssignedShiftBlockSchema } from './schedule';
import { IntervalSchema, dateString } from './time';
import { nonnegativeInteger } from './utils';

const ShiftChangeRequestSchema = z.object({ newIntervals: z.array(IntervalSchema) });

export const ScheduleChangeRequestStatusSchema = z.enum([
  'pending',
  'approved',
  'rejected',
  'void',
]);

export type ScheduleChangeRequestStatus = z.infer<typeof ScheduleChangeRequestStatusSchema>;

export const ScheduleChangeRequestSchema = z.object({
  organizationId: OrganizationIdSchema,
  surferId: SurferIdSchema,
  originalStart: dateString,
  originalEnd: dateString,
  originalShiftTemplateId: ShiftTemplateIdSchema.nullish(),
  originalShiftTemplateVersion: nonnegativeInteger.nullish(),
  request: ShiftChangeRequestSchema,
  surferNote: z.string().nullable(),
  managerNote: z.string().nullable(),
  status: ScheduleChangeRequestStatusSchema,
  reviewedAt: dateString.nullable(),
  reviewedBy: ManagerIdSchema.nullable(),
});

export const PersistedScheduleChangeRequestSchema = ScheduleChangeRequestSchema.extend({
  id: ScheduleChangeRequestIdSchema,
  createdAt: dateString,
  updatedAt: dateString,
  deletedAt: dateString.nullable(),
});

export const PersistedScheduleChangeRequestWithShiftSchema =
  PersistedScheduleChangeRequestSchema.extend({
    matchingShift: z.optional(AssignedShiftBlockSchema),
  });

export type PersistedScheduleChangeRequestWithShift = z.infer<
  typeof PersistedScheduleChangeRequestWithShiftSchema
>;

export type ScheduleChangeRequest = z.infer<typeof ScheduleChangeRequestSchema>;
export type PersistedScheduleChangeRequest = z.infer<typeof PersistedScheduleChangeRequestSchema>;

export const CreateScheduleChangeRequestSchema = ScheduleChangeRequestSchema.omit({
  organizationId: true,
  surferId: true,
  status: true,
  managerNote: true,
  reviewedAt: true,
  reviewedBy: true,
});
export type CreateScheduleChangeRequest = z.infer<typeof CreateScheduleChangeRequestSchema>;

export const ReviewScheduleChangeRequestReqSchema = z.object({
  status: z.enum(['approved', 'rejected']),
  managerNote: z.optional(z.string().nullable()),
});
export type ReviewScheduleChangeRequestReq = z.infer<typeof ReviewScheduleChangeRequestReqSchema>;

export const GetScheduleChangeRequestRespSchema = pagedResultsSchema(
  PersistedScheduleChangeRequestWithShiftSchema,
);
export type GetScheduleChangeRequestResp = z.infer<typeof GetScheduleChangeRequestRespSchema>;
