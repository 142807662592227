import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import 'focus-visible';
import { Suspense, lazy } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import theme from './design-system/theme';
import { useManagerId } from './hooks/useManagerId';
import { useOrgId } from './hooks/useOrgId';
import { useRoles } from './hooks/useRoles';
import { AuthErrorPage } from './pages/AuthErrorPage';
import { LoginPage } from './pages/LoginPage';
import { SharedRoutes } from './routes/appRoutes';
import { CenteredLoading } from './shared-components/CenteredLoading';
import { getLocalStorageItem, setLocalStorageItem } from './utils/localStorage';

function Loader() {
  return (
    <Box width="100%" height="100%">
      <CenteredLoading />
    </Box>
  );
}

export const App = () => {
  const { isLoading, error, user } = useAuth0();
  const orgId = useOrgId();
  const managerId = useManagerId();

  const roles = useRoles();

  const { boot } = useIntercom();
  if (user) {
    boot({
      name: user.name,
      email: user.email,
      actionColor: theme.colors.purple[30],
      backgroundColor: theme.colors.yellow[30],
      customLauncherSelector: '#custom-intercom-launcher',
      hideDefaultLauncher: true,
      customAttributes: {
        roles,
        organization: orgId,
        managerId,
      },
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <AuthErrorPage error={error} />;
  }

  return (
    <Router>
      <Switch>
        <Route path={SharedRoutes.LOGIN} component={LoginPage} />
        <AuthenticatedRoutes />
      </Switch>
    </Router>
  );
};

const ManagerRouterLazy = lazy(() =>
  import('./routes/ManagerRouter').then(({ ManagerRouter }) => ({
    default: ManagerRouter,
  })),
);
const ManagerRouter = () => (
  <Suspense fallback={<Loader />}>
    <ManagerRouterLazy />
  </Suspense>
);

const SurferRouterLazy = lazy(() =>
  import('./routes/SurferRouter').then(({ SurferRouter }) => ({
    default: SurferRouter,
  })),
);
const SurferRouter = () => (
  <Suspense fallback={<Loader />}>
    <SurferRouterLazy />
  </Suspense>
);

export class InvalidRoleError extends Error {
  readonly _tag = 'InvalidRole';

  error: string;
  error_description: string;

  constructor(message: string, error: string) {
    super(message);
    this.error = error;
    this.error_description = message;
  }
}

function AuthenticatedRoutes() {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const roles = useRoles();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: SharedRoutes.LOGIN,
          search: location?.search,
        }}
      />
    );
  }

  const view = getLocalStorageItem('view');
  const isManager = roles.includes('manager');
  const isSurfer = roles.includes('surfer');

  // first we try to respect the user's roles and their `view` state
  if (isManager && (!view || view === 'manager')) {
    return <ManagerRouter />;
  } else if (isSurfer && (!view || view === 'surfer')) {
    return <SurferRouter />;
  }

  // next handle the case where have a valid role that does not respect the `view` state
  if (isManager) {
    setLocalStorageItem('view', 'manager');
    return <ManagerRouter />;
  } else if (isSurfer) {
    setLocalStorageItem('view', 'surfer');
    return <SurferRouter />;
  }

  // if they do not have a valid role then show auth error page
  const error = new InvalidRoleError('User does not have valid role', 'Access denied');
  return <AuthErrorPage error={error} />;
}

export default App;
