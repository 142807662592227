import { useAuth0 } from '@auth0/auth0-react';
import { BaseLoginOptions } from '@auth0/auth0-spa-js';
import { useLocation } from 'react-router-dom';

import { useOnMount } from '../hooks/useOnMount';
import { CenteredLoading } from '../shared-components/CenteredLoading';

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const loginParams: BaseLoginOptions = {};

  if (query.get('invitation') && query.get('organization')) {
    loginParams.invitation = query.get('invitation') || undefined;
    loginParams.organization = query.get('organization') || undefined;
  }

  useOnMount(() => {
    loginWithRedirect(loginParams);
  });

  return <CenteredLoading />;
};
