import { z } from 'zod';

import {
  ApiKeyConnectionProviderNameSchema,
  ClientSecretConnectionProviderNameSchema,
} from './connection-providers';
import { ConnectionIdSchema, OrganizationIdSchema, ProviderIdSchema } from './ids';
import { dateString } from './time';

const ConnectionSchema = z.object({
  connectionId: ConnectionIdSchema,
  providerId: ProviderIdSchema,
});
export type Connection = z.infer<typeof ConnectionSchema>;

const PerformanceConnectionSchema = z.object({
  organizationId: OrganizationIdSchema,
  providerId: ProviderIdSchema,
  createdAt: dateString,
  deletedAt: dateString.nullable(),
});
export const PerformanceConnectionsSchema = PerformanceConnectionSchema.array();
export type PerformanceConnections = z.infer<typeof PerformanceConnectionsSchema>;

export const GetConnectionsRespSchema = ConnectionSchema.array();
export type GetConnectionsResp = z.infer<typeof GetConnectionsRespSchema>;

export const CreateAPIKeyConnectionReqSchema = z.object({
  providerName: ApiKeyConnectionProviderNameSchema,
  accessToken: z.string().min(1),
});
export type CreateAPIKeyConnectionReq = z.infer<typeof CreateAPIKeyConnectionReqSchema>;

export const CreateClientSecretConnectionReqSchema = z.object({
  providerName: ClientSecretConnectionProviderNameSchema,
  subdomain: z.string().min(1),
  clientId: z.string().min(1),
  clientSecret: z.string().min(1),
});
export type CreateClientSecretConnectionReq = z.infer<typeof CreateClientSecretConnectionReqSchema>;

export type CreateConnectionReq = CreateAPIKeyConnectionReq | CreateClientSecretConnectionReq;

export const ConnectionTicketGroupFieldSuggestionRespSchema = z.array(
  z.object({
    name: z.string(),
    value: z.string(),
  }),
);
export type ConnectionTicketGroupFieldSuggestionResp = z.infer<
  typeof ConnectionTicketGroupFieldSuggestionRespSchema
>;

const BackfillSchema = z.object({
  id: z.number(),
  complete: z.boolean(),
  connectionId: ConnectionIdSchema,
  endpoint: z.string(),
  createdAt: dateString,
  updatedAt: dateString,
});
export type Backfill = z.infer<typeof BackfillSchema>;

export const BackfillStatusRespSchema = z.array(BackfillSchema);
export type BackfillStatusResp = z.infer<typeof BackfillStatusRespSchema>;
