import z from 'zod';

export const COLORS = [
  'purple',
  'yellow',
  'teal',
  'orange',
  'blue',
  'green',
  'pink',
  'olive',
  'royalBlue',
  'berry',
  'beige',
  'gray',
] as const;

export const ColorSchema = z.enum(COLORS);
export type Color = z.infer<typeof ColorSchema>;

export const COLORS_EXTENDED = [...COLORS, 'red', 'white'] as const;

export const ExtendedPaletteColorSchema = z.enum(COLORS_EXTENDED);
export type ExtendedPaletteColor = z.infer<typeof ExtendedPaletteColorSchema>;
