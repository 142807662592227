import z from 'zod';

import { StreamIdSchema } from './ids';
import { nonnegativeInteger } from './utils';

const CoverageSnapshotSchema = z.object({
  required: nonnegativeInteger,
  scheduled: nonnegativeInteger,
});
export type CoverageSnapshot = z.infer<typeof CoverageSnapshotSchema>;

const CoverageForTaskSchema = z.object({
  streamId: StreamIdSchema,
  coverage: CoverageSnapshotSchema,
});

const CoverageAggregationSchema = z.record(z.string(), z.array(CoverageForTaskSchema));
export type CoverageAggregation = z.infer<typeof CoverageAggregationSchema>;

export const CoveragePayloadSchema = z.object({
  buckets: CoverageAggregationSchema,
});
export type CoveragePayload = z.infer<typeof CoveragePayloadSchema>;
