import { useAuth0 } from '@auth0/auth0-react';

export const useIsSurfboardEmployee = (): boolean => {
  const { user } = useAuth0();

  const email = user?.email ?? '';
  const email_verified = user?.email_verified === true;

  return email_verified && email.endsWith('@teamsurfboard.com');
};
