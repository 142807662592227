import { ComponentStyleConfig } from '@chakra-ui/react';

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];

const Modal: ComponentStyleConfig = {
  parts,
  baseStyle: {
    overlay: {
      bg: '#27262B4D', // gray.50 30% opacity
    },
    dialog: {
      borderRadius: '12px',
      bg: 'white',
      padding: '24px',
      boxShadow:
        '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
    },
    header: {
      px: 0,
      py: 0,
      mb: '8px',
      fontSize: 'f3',
      fontWeight: 'bold',
      lineHeight: 'heading',
    },
    body: {
      px: 0,
      py: 0,
      mb: '32px',
      fontSize: 'f2',
      fontWeight: 'normal',
      lineHeight: 'paragraph',
      color: 'gray.40',
    },
    footer: {
      px: 0,
      py: 0,
    },
  },
  variants: {
    mobileBottomSheet: {
      dialog: {
        maxWidth: 'none',
        marginBottom: 0,
        marginTop: 'auto',
        borderRadius: '24px 24px 0 0',
        paddingTop: '24px',
        paddingX: '12px',
      },
      body: {
        mb: 0,
      },
    },
    'fit-content': {
      dialog: {
        maxWidth: 'none',
      },
    },
  },
};

export default Modal;
