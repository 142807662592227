import z from 'zod';

import { SurferIdSchema } from './ids';
import { pagedResultsSchema } from './pagination';
import { BlockSchema } from './schedule';
import { IntervalSchema } from './time';

export const ConflictIdSchema = z.string().min(1).brand<'ConflictId'>();
export type ConflictId = z.infer<typeof ConflictIdSchema>;

export const ConflictOverlapSchema = z.object({
  id: ConflictIdSchema,
  acked: z.boolean().optional(),
  type: z.literal('conflict-overlap'),
  blocks: z.array(BlockSchema),
  interval: IntervalSchema,
  surferId: SurferIdSchema,
});
export type ConflictOverlap = z.infer<typeof ConflictOverlapSchema>;

export const ConflictSchema = ConflictOverlapSchema;
export type Conflict = z.infer<typeof ConflictSchema>;

export const GetConflictsRespSchema = pagedResultsSchema(ConflictSchema);
export type GetConflictsResp = z.infer<typeof GetConflictsRespSchema>;
