import z from 'zod';

/**
 * Creates a new zod type representing paginated results of a specific type.
 *
 * This should be used to represent any paginated result where being able
 * to scroll to the previous or next page is the only action we need to support.
 *
 *
 * ⚠️ WARNING: do NOT use this when you need pagination where you should be able
 * to jump to a specific page.
 *
 * @param schema schema for the result items.
 * @returns the new zod type.
 */
export function pagedResultsSchema<T>(schema: z.ZodType<T>) {
  return z.object({
    results: z.array(schema),
    pageInfo: z.object({
      prev: z.string().nullable(),
      next: z.string().nullable(),
    }),
  });
}

/**
 * Interface representing the result of `pagedResultsSchema`.
 */
export interface PagedResults<T> {
  results: T[];
  pageInfo: {
    prev: string | null;
    next: string | null;
  };
}

/**
 * Creates a new zod type representing a limited result.
 *
 * This should be used to represent any collection of results that
 * might not be returned in a single response and can not be possible
 * to paginate.
 *
 * The client is expected to handle the case where the results list
 * is incomplete checking the flag `hasMore`.
 *
 * @param schema schema for the result items.
 * @returns the new zod type.
 */
export function limitedResultsSchema<T>(schema: z.ZodType<T>) {
  return z.object({
    results: z.array(schema),
    hasMore: z.boolean(),
  });
}

/**
 * Interface representing the result of `limtedResultsSchema`.
 */
export interface LimitedResults<T> {
  results: T[];
}
