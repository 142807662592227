import { FC } from 'react';

import capitalize from '../../lib/capitalize';
import cx from '../../lib/cx';
import { GenericComponentProps } from '../types';
import './style.scss';

export type PaddingSizeType = 'compact' | 'medium' | 'spacious';

export interface PanelProps extends GenericComponentProps {
  direction?: 'row' | 'column';
  lineSeparated?: boolean;
  grow?: boolean;
  justify?: 'center' | 'end';
  width?: 'narrow' | 'medium';
  padding?: PaddingSizeType;
  paddingX?: PaddingSizeType;
  paddingY?: PaddingSizeType;
  testId?: string;
}

export const Panel: FC<React.PropsWithChildren<PanelProps>> = ({
  spacing,
  children,
  direction = 'column',
  lineSeparated = false,
  grow = false,
  justify,
  width,
  padding,
  paddingX,
  paddingY,
  style,
  testId,
}) => {
  const name = 'Panel';
  const px = paddingX ?? padding;
  const py = paddingY ?? padding;
  const classes = cx(
    `${name}`,
    {
      [`${name}--H`]: direction === 'row',
      [`${name}--V`]: direction === 'column',
      [`${name}--LineSeparated`]: lineSeparated,
      [`${name}--Grow`]: grow,
    },
    !!justify && `${name}--Justify${capitalize(justify)}`,
    !!width && `${name}--Width${capitalize(width)}`,
    !!px && `${name}--PaddingX${capitalize(px)}`,
    !!py && `${name}--PaddingY${capitalize(py)}`,
    spacing,
  );
  return (
    <div className={classes} style={style} data-testid={testId}>
      {children}
    </div>
  );
};
