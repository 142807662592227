import { SystemStyleObject } from '@chakra-ui/theme-tools';

const tertiaryHoverStyles = {
  background: 'purple.10',
  color: 'purple.30',
  border: 'none',
};

const _Button = {
  baseStyle: {
    borderRadius: '4px',
    fontWeight: 'semibold',
    fontFamily: 'Inter, sans-serif',
    // prevent platform-specific styling
    WebkitAppearance: 'none !important',
    MozAppearance: 'none !important',
  },
  sizes: {
    sm: {
      fontSize: 'f1',
      padding: '2px 8px',
      maxHeight: '22px',
    },
    md: {
      fontSize: 'f2',
      padding: '6px 12px',
      maxHeight: '32px',
    },
    large: {
      fontSize: 'f2',
      padding: '10px 16px',
      minHeight: '40px',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
  variants: {
    primary: {
      bg: 'purple.30',
      color: 'white',
      _hover: {
        bg: 'purple.40',
        _disabled: {
          bg: 'purple.20',
          color: 'white',
        },
      },
      _disabled: {
        cursor: 'not-allowed',
        bg: 'purple.20',
      },
    },
    secondary: {
      border: '1px solid',
      bg: 'white',
      borderColor: 'inputBorderColor',
      color: 'gray.40',
      _hover: {
        borderColor: 'purple.30',
        color: 'purple.30',
      },
      _disabled: {
        cursor: 'not-allowed',
        borderColor: 'none',
        color: 'gray.40',
        opacity: 0.4,
      },
    },
    tertiary: {
      border: 'none',
      color: 'gray.40',
      _hover: tertiaryHoverStyles,
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    'tertiary-active': tertiaryHoverStyles,
    danger: {
      bg: 'error.30',
      color: 'white',
      _hover: {
        bg: 'error.40',
      },
      _disabled: {
        cursor: 'not-allowed',
        borderColor: 'none',
        color: 'error.30',
        opacity: 0.4,
      },
    },
    success: {
      bg: 'success.30',
      color: 'white',
      _hover: {
        bg: 'success.40',
      },
      _disabled: {
        cursor: 'not-allowed',
        borderColor: 'none',
        color: 'success.30',
        opacity: 0.4,
      },
    },
  },
};

const Button: SystemStyleObject = {
  ..._Button,
  variants: {
    ..._Button.variants,
    'new-primary': {
      ..._Button.variants.primary,
      borderRadius: 'md',
    },
    'new-secondary': {
      ..._Button.variants.secondary,
      borderColor: 'gray.30',
      borderRadius: 'md',
    },
    'new-tertiary': {
      ..._Button.variants.tertiary,
      borderRadius: 'md',
    },
    'new-tertiary-active': tertiaryHoverStyles,
    'new-danger': {
      ..._Button.variants.danger,
      borderRadius: 'md',
    },
    'new-success': {
      ..._Button.variants.success,
      borderRadius: 'md',
    },
  },
};

export default Button;
