import { z } from 'zod';

import { SurferIdSchema, TeamId, TeamIdSchema } from './ids';
import { convertNullToUndefined } from './utils';

export const TeamSchema = z.object({
  name: z.string().min(1),
  description: convertNullToUndefined(z.string().min(1)),
  surfers: z.array(SurferIdSchema),
});

export type Team = z.infer<typeof TeamSchema>;

export const PersistedTeamSchema = TeamSchema.extend({
  id: TeamIdSchema,
});

export const GetTeamsRespSchema = PersistedTeamSchema.array();
export const GetTeamRespSchema = PersistedTeamSchema;
export type GetTeamsResp = z.infer<typeof GetTeamsRespSchema>;
export type PersistedTeam = z.infer<typeof PersistedTeamSchema>;

export const CreateTeamReqSchema = TeamSchema;
export type CreateTeamReq = z.infer<typeof CreateTeamReqSchema>;

export const PutTeamReqSchema = TeamSchema;
export type PutTeamReq = z.infer<typeof PutTeamReqSchema>;

export const UNASSIGNED_TEAM_ID = -1 as TeamId;
