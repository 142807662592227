import z from 'zod';

import { TimezoneIanaSchema } from './time';
import { convertNullToUndefined, trimmedString } from './utils';

const OrganizationSettingsSchema = z.object({
  shiftPlanningEnabled: convertNullToUndefined(z.boolean()),
  schedulingEnabled: z.boolean(),
  timeOffIcalLink: convertNullToUndefined(trimmedString),
  zendeskSubdomain: convertNullToUndefined(trimmedString),
  salesforceSubdomain: convertNullToUndefined(trimmedString),
  talkdeskSubdomain: convertNullToUndefined(trimmedString),
});

const PersistedOrganizationSettingsSchema = OrganizationSettingsSchema.merge(
  z.object({ id: z.number() }),
);

export const PersistedOrganizationSettingsAndTimezoneSchema =
  PersistedOrganizationSettingsSchema.merge(
    z.object({
      timezone: TimezoneIanaSchema,
    }),
  );

export type OrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;

export type PersistedOrganizationSettingsAndTimezone = z.infer<
  typeof PersistedOrganizationSettingsAndTimezoneSchema
>;

export const GetOrgSettingsRespSchema = PersistedOrganizationSettingsAndTimezoneSchema;
export type GetOrgSettingsResp = z.infer<typeof PersistedOrganizationSettingsAndTimezoneSchema>;

export const UpdateOrgSettingsReqSchema = OrganizationSettingsSchema.partial();
export type UpdateOrgSettingsReq = z.infer<typeof UpdateOrgSettingsReqSchema>;

export const UpdateOrgSettingsRespSchema = PersistedOrganizationSettingsSchema;
