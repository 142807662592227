import React, { HTMLAttributeAnchorTarget } from 'react';

import cx from '../../lib/cx';
import { GenericComponentProps } from '../types';
import './style.scss';

export type LinkProps = {
  text: string;
  target?: HTMLAttributeAnchorTarget | undefined;
} & GenericComponentProps &
  React.HTMLProps<HTMLAnchorElement>;

// Simple <a> tag. If you want a Button that's a link, use the Button component
export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
  spacing = '',
  text,
  className = '',
  ...rest
}) => {
  const linkClass = cx('Link', spacing, className);
  return (
    <a className={linkClass} {...rest}>
      {text}
    </a>
  );
};
