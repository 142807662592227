import { SystemStyleObject } from '@chakra-ui/react';

// prettier-ignore
const borderRadius: SystemStyleObject = {
  none: '0',      // 0px
  sm: '0.25rem',  // 4px
  md: '0.375rem', // 6px
  lg: '0.5rem',   // 8px
  xl: '0.75rem',  // 12px
  super: '1rem',  // 16px
};

export default borderRadius;
