import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const variants = {
  warning: {
    borderColor: 'red.30',
    backgroundColor: '#F3707040', // red.30 25%
  },
  info: {
    borderColor: 'yellow.40',
    backgroundColor: 'yellow.10',
  },
  'info-no-icon': {
    borderColor: 'yellow.40',
    backgroundColor: 'yellow.10',
    padding: '12px',
  },
} as const;

const Alert: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '8px 12px 8px 8px',
    border: '1px solid',
    borderRadius: '6px',
  },
  variants,
};

export default Alert;
