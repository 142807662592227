import { z } from 'zod';

import { SurferIdSchema } from './ids';

export const features = ['availability'] as const;
export const FeatureNameSchema = z.enum(features);
export type FeatureName = z.infer<typeof FeatureNameSchema>;

export const SurferFeatureSettingSchema = z.object({
  surferId: SurferIdSchema,
  featureName: FeatureNameSchema,
  enabled: z.boolean(),
  settings: z.unknown(),
});
export type SurferFeatureSetting = z.infer<typeof SurferFeatureSettingSchema>;

export const GetSurferFeaturesRespSchema = SurferFeatureSettingSchema.array();
export type GetSurferFeaturesResp = z.infer<typeof GetSurferFeaturesRespSchema>;

export const UpdateSurferFeaturesReqSchema = SurferFeatureSettingSchema.array();
export type UpdateSurferFeaturesReq = z.infer<typeof UpdateSurferFeaturesReqSchema>;
