import { z } from 'zod';

import { OrganizationIdSchema, SurferIdSchema } from './ids';

export const ICalExportURLSchema = z.object({
  url: z.string().nullable(),
});

export const ICalExportRequestSchema = z.object({
  organizationId: OrganizationIdSchema,
  surferId: SurferIdSchema,
});

export type ICalExportURL = z.infer<typeof ICalExportURLSchema>;
export type ICalExportRequest = z.infer<typeof ICalExportRequestSchema>;
