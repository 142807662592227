import { SystemStyleObject } from '@chakra-ui/theme-tools';

const FormErrorMessage: SystemStyleObject = {
  baseStyle: {
    color: 'red.error',
    fontSize: 'f1',
  },
};

export default FormErrorMessage;
