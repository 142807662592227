import { Circle } from '@chakra-ui/react';
import { CSSProperties, FC } from 'react';

import cx from '../../lib/cx';
import { GenericComponentProps } from '../types';
import iconSprite from './iconSprite.svg';
import './style.scss';

export const iconNames = [
  'add-item',
  'add-user',
  'add',
  'archive',
  'arrow-down-chunky',
  'arrow-down',
  'arrow-drop-down',
  'arrow-drop-up',
  'arrow-left-long',
  'arrow-left',
  'arrow-right-thin',
  'arrow-right',
  'arrow-swap-horizontal',
  'arrow-up-chunky',
  'arrow-up',
  'arrows-up-down',
  'bin',
  'book',
  'break',
  'briefcase',
  'broom',
  'bucket',
  'calendar-add',
  'calendar-edit',
  'calendar-today',
  'calendar',
  'call',
  'chainlink',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clipboard-tick',
  'clock-filled',
  'clock-plus',
  'clock',
  'close-circle',
  'close',
  'cog',
  'copy-success',
  'copy',
  'cross',
  'dashed-circle',
  'direct-normal',
  'direct-send',
  'document-download',
  'dot',
  'double-arrow-left',
  'double-arrow-right',
  'duplicate',
  'edit-3',
  'edit',
  'ellipsis',
  'emoji-events',
  'emoji',
  'exclamation-point',
  'export',
  'eye-slash',
  'eye',
  'file-download',
  'filter',
  'forecast',
  'gift',
  'glass',
  'google-calendar',
  'google-meet',
  'hourglass',
  'inbox',
  'info-circle',
  'info',
  'lamp-charge',
  'like',
  'list-check',
  'lock',
  'login',
  'logout',
  'magic-pen',
  'medal-star',
  'medal-star',
  'message-edit',
  'message-question',
  'message-remove',
  'message',
  'messages-2',
  'messages',
  'minus-circle',
  'minus',
  'moon-outline',
  'moon',
  'moving-star',
  'ms-teams',
  'notification-bing',
  'pause-circle',
  'pause-circle',
  'people',
  'percentage-squircle',
  'pin',
  'pizza',
  'plus',
  'profile-2user',
  'profile-remove',
  'question-circle',
  'redo',
  'refresh-2',
  'refresh',
  'rotation',
  'schedule',
  'search',
  'send-message',
  'send',
  'setting-4',
  'shift-pattern',
  'show-chart',
  'shuffle',
  'size',
  'slack',
  'slash',
  'sms-edit',
  'sms-tracking',
  'sms-star',
  'sparkle',
  'spotify',
  'star',
  'status-up',
  'sun',
  'surfer',
  'thunder',
  'tick-circle',
  'tick',
  'timer',
  'undo',
  'user',
  'volume-low',
  'warning-circle-filled',
  'warning-circle',
  'warning',
  'zoom',
] as const;

export type IconName = (typeof iconNames)[number];

export interface IconProps extends GenericComponentProps {
  name: IconName;
  size?: string;
  transform?: CSSProperties['transform'];
}

/**
 * Icon component
 *
 * @param params.name - The name (id) of the icon
 */
export const Icon: FC<React.PropsWithChildren<IconProps>> = ({
  name,
  spacing,
  size,
  transform,
}) => {
  const _ = 'Icon';
  const classes = cx(_, spacing);

  return (
    <svg
      className={classes}
      style={size ? { width: size, height: size, transform } : { transform }}
      data-testid={`icon-${name}`}
    >
      <use xlinkHref={`${iconSprite}#${name}`} />
    </svg>
  );
};

type IconBadgeProps = { name: IconName; sizePx: number };
export const IconBadge = ({ name, sizePx }: IconBadgeProps) => {
  const circleSize = `${sizePx}px`;
  const iconSize = `${sizePx * 0.7}px`;
  return (
    <Circle
      backgroundColor={'gray.5'}
      color="gray.40"
      boxShadow={'0px 1px 2px rgba(178, 178, 185, 0.45)'}
      size={circleSize}
      position="relative"
    >
      <Icon name={name} size={iconSize} />
    </Circle>
  );
};
