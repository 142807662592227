import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const baseStyle = definePartsStyle({
  container: {
    border: '1px solid',
    borderRadius: '117px',
    borderColor: 'gray.30',
    _checked: {
      borderColor: 'green.30',
    },
  },
  thumb: {
    width: '17px',
    height: '17px',
    margin: '4px',
    bg: 'gray.30',
    _checked: {
      bg: 'success.30',
    },
  },
  track: {
    padding: '0px',
    bg: 'gray.10',
    _checked: {
      bg: 'green.10',
    },
  },
});

const sizes = {
  default: definePartsStyle({
    container: {
      [$width.variable]: '47px',
      [$height.variable]: '25px',
    },
  }),
};

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { size: 'default' },
});
