import Decimal from 'decimal.js';
import { IntervalSchema, StreamIdSchema as TicketGroupIdSchema, dateString } from 'shared-types';
import * as z from 'zod';
import { ZodNumber } from 'zod';

export const ZDecimal = z.number().transform(val => new Decimal(val));
export const Percentage = ZDecimal.brand<'Percentage'>();
export type Percentage = z.infer<typeof Percentage>;

export const PercentageChange = Percentage.brand<'Change'>().refine(
  d => d.gte(-1),
  'Percentage change must not be less than -1',
);

export const DecimalSurferEstimationVariables = z.object({
  shrinkage: Percentage.refine(d => d.gte(0) && d.lte(1), 'Shrinkage must be between 0 and 1'),
  ticketVolumePercentageChange: PercentageChange,
  averageHandleTimePercentageChange: PercentageChange,
  targetResponseTimePercentageChange: PercentageChange,
  serviceLevelPercentageChange: PercentageChange,
  concurrency: ZDecimal,
});
export type DecimalSurferEstimationVariables = z.infer<typeof DecimalSurferEstimationVariables>;

export const EstimationStep = z.enum(['hour', 'day']);
export type EstimationStep = z.infer<typeof EstimationStep>;

const SurferEstimationVariables = z.object<
  Record<keyof DecimalSurferEstimationVariables, ZodNumber>
>({
  shrinkage: z.number(),
  ticketVolumePercentageChange: z.number(),
  averageHandleTimePercentageChange: z.number(),
  targetResponseTimePercentageChange: z.number(),
  serviceLevelPercentageChange: z.number(),
  concurrency: z.number(),
});

export const EstimateSurfersRequestBody = z.object({
  interval: IntervalSchema,
  variables: SurferEstimationVariables,
  ticketGroups: TicketGroupIdSchema.array(),
});
export type EstimateSurfersRequestBody = z.infer<typeof EstimateSurfersRequestBody>;

/**
 * Represents the results of the Erlang C calculation for a ticket group on a certain date.
 */
export const EstimationDataForTicketGroup = z.object({
  ticketGroupId: TicketGroupIdSchema,
  date: dateString,
  ticketGroupName: z.string(),
  surfers: z.number(),
  tickets: z.number(),
  serviceLevel: z.number(),
});
export type EstimationDataForTicketGroup = z.infer<typeof EstimationDataForTicketGroup>;

export const EstimationDataForTicketGroups = z.array(EstimationDataForTicketGroup);
export type EstimationDataForTicketGroups = z.infer<typeof EstimationDataForTicketGroups>;
