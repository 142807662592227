import { FC } from 'react';

import cx from '../../lib/cx';
import { GenericComponentProps } from '../types';
import './style.scss';

export interface ProgressProps extends GenericComponentProps {
  running?: boolean;
}

export const Progress: FC<React.PropsWithChildren<ProgressProps>> = ({
  running = false,
  spacing,
}) => {
  const _ = 'Progress';
  const classes = cx(_, { [`${_}--Running`]: running }, spacing);

  return (
    <svg className={classes} viewBox="0 0 50 10" data-testid="progress-dot">
      <circle className="Progress__Dot" cx="5" cy="5" r="5" />
      <circle className="Progress__Dot" cx="25" cy="5" r="5" />
      <circle className="Progress__Dot" cx="45" cy="5" r="5" />
    </svg>
  );
};
