import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      fontFamily: 'var(--chakra-fonts-body)',
      fontSize: 'var(--chakra-fontSizes-f2)',
      color: 'var(--chakra-colors-gray-40)',
      textTransform: 'none',
    },
  },
};

export default Table;
